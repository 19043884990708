import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import { getSentryConfigFromEnv } from './app/parseEnv';
import { BadArguments } from './errors';

import isDev from './helpers/isDev';

let instance = null;

function createSentryDev(/* { Vue, router  } */) {
  return {
    captureException: (error) => {
      try {
        console.error('captureException', error);
      } catch {
        // swallow any errors if for some reason we couldn't log error, to prevent
        // infinite looping
      }
    },
  };
}

function createSentryLive({ Vue, router }) {
  const { dsn, origins } = getSentryConfigFromEnv();
  return Sentry.init({
    Vue,
    dsn,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: origins || [],
      }),
      new Sentry.Replay(),
    ],
    attachStacktrace: true,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    tracesSampleRate: 0.1,
    trackComponents: true,
  });
}

function createSentry(...args) {
  return isDev
    ? createSentryDev(...args)
    : createSentryLive(...args);
}

export default function getSentryInstance() {
  if (!instance) {
    throw new BadArguments('getSentryInstance was called before initSentry has been run');
  }
  return instance;
}

export function initSentry({ Vue, router }) {
  if (!(router && Vue)) {
    throw new BadArguments('initSentry was called without required arguments');
  }

  instance = createSentry({ Vue, router });
}
