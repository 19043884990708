/* eslint-disable max-classes-per-file */

export class BaseError extends Error {
  constructor(message = 'Unknown WebappError', details = {}) {
    super(message);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this);
    } else {
      this.stack = (new Error()).stack;
    }

    this.details = details;
  }

  static fromError(error, message = undefined, details = {}) {
    const newError = new this(message || error.message, { cause: error, ...details });
    return newError;
  }

  static createSubclass(className) {
    const wrapper = {
      [className]: class extends this {
        // eslint-disable-next-line no-shadow
        constructor(message, details = {}) {
          super(message, details);
          this.name = className;
        }
      },
    };

    return wrapper[className];
  }
}

export class BadArguments extends BaseError { }

export class FatalError extends BaseError {
  constructor(message = 'Unknown fatal error', details = {}) {
    super(message, details);

    console.error('A fatal error occurred', this);
    // how to best signal an non-recoverable error in browser?
  }
}

export class ValidationError extends BaseError { }
export class NetworkError extends BaseError {
  static fromApolloError(error) {
    return new NetworkError(
      error?.networkError?.result?.error?.message
      || error?.networkError?.message
      || error?.message,
      { ...error, ...(error?.networkError || {}) },
    );
  }

  isNotLoggedIn() {
    return this.message === 'Login required';
  }
}
export class GraphQLError extends BaseError {
  static fromApolloError(error) {
    return new GraphQLError(
      error?.gqlError?.message
      || error?.graphQLErrors[0]?.message
      || error?.message,
      { ...error, ...(error?.gqlError || {}), errors: error?.graphQLErrors || [] },
    );
  }
}
