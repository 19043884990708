let defaultEnv = process.env;

export function setEnv(env, { merge = false } = {}) {
  const previous = defaultEnv;
  if (merge) {
    defaultEnv = { ...defaultEnv, ...env };
  } else {
    defaultEnv = env;
  }
  return () => setEnv(previous);
}

export function getEnv() {
  return defaultEnv;
}

export function getApiUrlsFromEnv(env = defaultEnv) {
  const {
    VUE_APP_API_URL: api = '',
    VUE_APP_API_UPLOAD_URL: upload,
    VUE_APP_GRAPHQL_ENDPOINT: graphql = '',
  } = env;
  return { api, upload, graphql };
}

export function getSentryConfigFromEnv(env = defaultEnv) {
  const { VUE_APP_SENTRY_DSN: dsn = '', VUE_APP_SENTRY_ORIGINS: originText = '' } = env;
  const origins = originText.trim().split(/\s*,\s*/);
  return { dsn, origins };
}

export function getTableWavelengthsFromEnv(env = defaultEnv) {
  const { VUE_APP_TABLE_WAVELENGTHS: tableWavelengths = '280,420,520,620' } = env;
  return tableWavelengths.split(',').map(Number);
}

export function getBaseTitleFromEnv(env = defaultEnv) {
  const { VUE_APP_BASE_TITLE: baseTitle = '' } = env;
  return baseTitle;
}

export function getAuthConfigFromEnv(env = defaultEnv) {
  const {
    VUE_APP_AUTH_CLIENT_ID: clientId,
    VUE_APP_AUTH_DOMAIN: domain = 'maramatest.us.auth0.com',
    VUE_APP_AUTH_AUDIENCE: audience = 'https://api.maramalabs.com',
  } = env;

  return {
    clientId,
    domain,
    audience,
  };
}

export function getApiTokenFromEnv(env = defaultEnv) {
  const { API_TOKEN: apiToken = '' } = env;
  return apiToken;
}

export function getBaseURLFromEnv(env = defaultEnv) {
  const { BASE_URL = '' } = env;
  return BASE_URL;
}

export function getEmailConfigFromEnv(env = defaultEnv) {
  const { CONTACT_EMAIL: contactEmail = 'insights@cloudspec.co.nz' } = env;
  return { contactEmail };
}

export function getNodeFromEnv(env = defaultEnv) {
  const { NODE_ENV: nodeEnv = '' } = env;
  return nodeEnv;
}

export function getMinMaxWavelengthsFromEnv(env = defaultEnv) {
  const {
    VUE_APP_COLOR_MIN_WAVELENGTH: minNM = 360,
    VUE_APP_MAX_WAVELENGTH: maxNM = 750,
  } = env;
  return { minNM, maxNM };
}

export function getSuggestedFieldsFromEnv(env = defaultEnv) {
  const { VUE_APP_SUGGESTED_FIELDS: suggestedFields = '' } = env;
  return suggestedFields;
}
